/* src/index.css */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

body a {
  text-decoration: none;
}

header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header h1 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

header nav {
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
}

header nav a {
  color: #fff;
  display: flex;
  align-items: center;
}

platform-logo {
  display: flex;
  width: "30px";
}

.header {
  width: 98%;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shop-header-logo {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.container-products {
  max-width: 1300px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.contact,
.contact-select {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 250px;
  padding: 10px;
  gap: 9px;
  background-color: #fff;
  border-radius: 8px;
  position: sticky;
  top: 10px;
}

.contact p,
.contact-select p,
.contact span,
.contact-select span {
  font-size: 14px;
  text-align: center;
}

.contact p,
.contact-select p {
  font-weight: bold;
}

.contact span,
.contact-select span {
  color: rgb(104, 112, 117);
}

.contact a,
.contact-select a {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(85, 189, 85);
  color: #fff;
  gap: 8px;
  padding: 10px 10px;
  border-radius: 8px;
  cursor: pointer;
}

.contact a img,
.contact-select a img {
  width: 20px;
  height: 20px;
}

.product-grid {
  display: grid;
  width: 90%;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  padding: 0px 20px 20px 20px;
  position: relative;
}

.product-card,
.product-card-select {
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.product-image-card-select {
  width: 400px;
  height: 300px;
}

.product-image {
}

.logo-container {
  position: absolute;
  top: 90px;
  /* Ajuste conforme necessário */
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Linha preta acima do logo */
  /* Espaçamento entre a linha e o logo */
  background: white;
  /* Fundo branco para o logo se destacar */
  border-radius: 50%;
  /* Ajuste se precisar de um contorno arredondado */
}

.freeshipping-logo {
  width: 20px;
}

.platform-logo {
  width: 30px;
  /* Ajuste conforme necessário */
  height: 30px;
}

.product-info {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  margin-bottom: 10px;
}

.product-info h3 {
  margin: 0;
  font-size: 15px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.shipping-price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.shipping {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  /* Espaço entre frete grátis e preço */
  font-size: small;
  color: green;
}

.shipping-price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.coupon {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: small;
  color: #9224ef;
}

.freeshipping-logo {
  width: 20px;
  margin-left: 10px;
  height: auto;
  margin-right: 5px;
  /* Espaço entre a imagem e o texto */
}

.coupon-logo {
  width: 20px;
  color: blue;
  height: auto;
  margin-left: 10px;
  margin-right: 5px;
}

.price {
  color: green;
  font-weight: bold;
  margin-left: 14px;
  font-size: 15px;
}

.product-card-select {
  align-items: center;
  margin: 20px 5px 0px 5px;
  border-radius: 20px 20px 30px 30px;
  width: 830px;
}

.product-info-price {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 14px;
  margin: 15px 0px 0px 0px;
  width: 90%;
}

.product-info-price .product-info-select {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
}

.product-info-select .title-shipping {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 90%;
}

.product-info-select .title-shipping h3 {
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;

  margin: 0;
}

.product-info-select .logo-container-select img {
  width: 50px;
  height: 50px;
}

.product-info-price .product-price {
  width: 80px;
}

.product-info-price .product-price p {
  margin: 0;
}

.product-info-price .product-price .title-price {
  font-size: 10px;
}

.product-info-price .product-price .price {
  font-size: 20px;
}

.product-card-select .product-footer-select .shipping {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 20px;

  width: 270px;
}

.product-card-select .product-footer-select .shipping p {
  margin: 0;
  color: #957097;
}

.product-card-select .product-footer-select .shipping .title-one {
  font-size: 14px;
}

.product-card-select .product-footer-select .shipping .title-two {
  font-size: 8px;
}

.product-card-select .product-footer-select .coupon {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 15px;
  width: 270px;
}
.product-card-select .product-footer-select .coupon .coupon-button {
  border: 2px dashed #bfbfbf;
  border-radius: 12px;
  padding: 2px;
}

.product-card-select .product-footer-select .coupon .coupon-button button {
  all: unset;
  background: #43f390;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 20px;

  border-radius: 10px;
  color: #4c5958;
  cursor: pointer;

  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  gap: 7px;
}

.product-card-select .product-footer-select .coupon .coupon-button button p {
  margin: 0;
}

.product-card-select .product-footer-select .coupon .tilte-coupon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-card-select .product-redirect {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.product-card-select .product-redirect button {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px 50px;
  background-color: #8c52ff;
  border-radius: 15px;

  color: #ffffff;
  font-width: bold;

  cursor: pointer;
}

.product-card-select .product-redirect p {
  font-size: 10px;
  color: #957097;
}

.contact-select {
  display: none;
}

@media (max-width: 820px) {
  .section {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contact {
    display: none;
  }

  .product-grid {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    padding: 0;
  }

  .product-card-select {
    width: 400px;
  }
}

@media (max-width: 500px) {
  .contact-select {
    display: block;
    width: 93%;
  }

  .main {
    padding: 10px;
  }

  .header {
    width: 90%;
  }
}

@media (max-width: 400px) {
  .product-grid {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(150px, 0.7fr));
    padding: 0;
  }

  .product-card-select {
    width: 365px;
  }
}
