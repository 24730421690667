.privacy-policy-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  h1 {
    text-align: center;
    color: #2c3e50;
    font-size: 28px;
  }
  
  h2 {
    color: #2980b9;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
    margin-top: 20px;
  }
  
  h3 {
    color: #34495e;
    font-size: 20px;
  }
  
  p {
    line-height: 1.6;
    font-size: 16px;
  }
  
  ul {
    margin-left: 20px;
    padding-left: 10px;
  }
  
  ul li {
    margin: 8px 0;
  }
  
  a {
    color: #2980b9;
    text-decoration: none;
    font-weight: bold;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .privacy-policy-container {
      width: 90%;
      padding: 15px;
    }
  
    h1 {
      font-size: 24px;
    }
  
    h2 {
      font-size: 20px;
    }
  
    p {
      font-size: 14px;
    }
  }
  